<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP SERVICE REQUEST EDIT

type    : view

uses    : header-view
          main-container
          main-card

route   : /serviceRequests/edit/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-service-request-edit">
    <header-view
      title="Edit Service Request"
      short_title="Edit SR"
      :btnOptions="[
        {
          name: 'Save',
          action: handleSubmit,
          btnColor: 'button-primary',
        },
      ]"
      closeBtn
      :previous_page="'/serviceRequests/view/' + uuid" />

    <main-container>
      <main-card>
        <v-form
          ref="form"
          @submit.prevent="handleSubmit"
          class="mt-2"
          id="serviceRequestForm">
          <v-row>
            <v-col :cols="12" :sm="6">
              <div class="title">Client</div>
              <div>
                {{ serviceRequest.requestor_name }}
              </div>
              <div>
                {{ serviceRequest.requestor_phone }}
              </div>
              <div>
                {{ serviceRequest.requestor_email }}
              </div>
            </v-col>

            <v-col :cols="12" :sm="6">
              <div class="title">Billing Address</div>
              <div>
                {{ serviceRequest.address }}
              </div>
              <div>
                {{ serviceRequest.city }}, {{ serviceRequest.state }}
                {{ serviceRequest.zip_code }}
              </div>
              <div>
                {{ serviceRequest.country }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                id="special_instructions"
                v-model="serviceRequest.special_instructions"
                label="Special Instructions"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <div class="headline" v-if="errorMessage !== ''">
          {{ errorMessage }}
          <v-btn @click="errorMessage = ''">OK</v-btn>
        </div>
      </main-card>
    </main-container>
  </div>
</template>

<script>
  // components
  import MainContainer from '@/components/main-container';
  import HeaderView from '@/components/header-view';
  import MainCard from '@/components/main-card';

  // mixins
  import Forms from '@/mixins/forms.js';

  // services
  import Jobs from '@/services/Jobs.service.js';

  export default {
    name: 'AppServiceRequestEdit',
    components: {
      'main-container': MainContainer,
      'header-view': HeaderView,
      'main-card': MainCard,
    },
    mixins: [Forms],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      var host = window.location.host;
      var subdomain = host.split('.')[0] || 'www';
      return {
        possible_serviceRequest_status: ['Active', 'Not Active'],
        errorMessage: '',
        loader: false,
        loading4: false,
        serviceRequest: {
          uuid: '',
          client_id: '',
          serviceRequest_name: '',
          requestor_name: '',
          requestor_phone: '',
          requestor_email: '',
          address: '',
          city: '',
          state: '',
          zip_code: '',
          country: '',
          tenant_url: subdomain,
          date_submitted: '',
          special_instructions: '',
        },
        submitted: false,
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    async mounted() {
      // console.log("AppServiceRequestEdit: ", this.uuid);

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get current serive request details for editing
      this.serviceRequest = await Jobs.getServiceRequest(
        this.uuid,
        accessToken
      );

      // console.log( "heres the status" )
      // console.log( this.serviceRequest.service_request_status )
      if (
        this.serviceRequest &&
        this.serviceRequest.service_request_status == 'Cancelled'
      ) {
        this.$router.push('/serviceRequests/view/' + this.uuid);
      }
    },
    methods: {
      async handleSubmit() {
        // validate form
        if (!this.$refs.form.validate()) {
          return false;
        }
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // create updated service request data object
        var updatedServiceRequest = {
          uuid: this.serviceRequest.uuid,
          client_id: this.serviceRequest.client_id,
          requestor_name: this.serviceRequest.requestor_name,
          requestor_phone: this.serviceRequest.requestor_phone,
          requestor_email: this.serviceRequest.requestor_email,
          address: this.serviceRequest.address,
          city: this.serviceRequest.city,
          state: this.serviceRequest.state,
          zip_code: this.serviceRequest.zip_code,
          country: this.serviceRequest.country,
          tenant_url: this.serviceRequest.tenant_url,
          special_instructions: this.serviceRequest.special_instructions,
        };

        // send request to the api service
        var res = await Jobs.updateServiceRequest(
          this.uuid,
          updatedServiceRequest,
          accessToken
        );

        // route back to service requst view or print error
        if (res) {
          this.$router.push('/serviceRequests/view/' + this.uuid);
        } else {
          this.errorMessage = 'Error updating service request';
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
